:root {
  --back: #0f0c16; // Primary dark background color
  --back2: #1b1625; // Lighter secondary background
  --border: #444; // Primary dark border color
  --border2: #666; // Lighter border (mostly for piano keys)
  --text: #ddd; // Primary light text
  --text2: #bbb; // Darker sub text
  --text3: #fff; // Highlight text
  --red: #943939; // Primary red
  --dark-red: #452222; // Darker red
  --light-red: #AA4242; // Highlight red
  --blue: #4860AC; // Primary blue
  --dark-blue: #2A3456; // Darker blue
  --light-blue: #4B68C6; // Highlight blue

  --light-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  --strong-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.5);
  --text-shadow: 0 4px 15px var(--back);

  // #555555
  --grey-filter: brightness(0) saturate(100%) invert(31%) sepia(18%) saturate(0%) hue-rotate(162deg) brightness(104%) contrast(100%);

  // #ddd
  --white-filter: invert(100%) sepia(2%) saturate(1026%) hue-rotate(6deg) brightness(114%) contrast(73%);

  --red-filter: brightness(0) saturate(100%) invert(29%) sepia(11%) saturate(2969%) hue-rotate(314deg) brightness(98%) contrast(94%);

  // #4860AC
  --blue-filter: brightness(0) saturate(100%) invert(34%) sepia(55%) saturate(649%) hue-rotate(188deg) brightness(96%) contrast(88%);  
}

*, *::before, *::after {
  box-sizing: border-box;
}

* {
  margin: 0;
}

body, html, #root {
  height: 100%;
  background-color: var(--back);
  color: var(--text);
  scrollbar-color: var(--border) transparent;
}

h1, h2, h3, h4, h5, p, button, input, span, div, a, textarea, select {
  font-weight: normal;
  font-family: 'Share Tech Mono', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Ubuntu, Arial, sans-serif;
  text-decoration: none;
  color: inherit;
  font-size: inherit;
}
.small-text {
  font-size: 0.95rem;
}
.med-text {
  font-size: 1.1rem;
}
.big-text {
  font-size: 1.2rem;
}
.bigger-text {
  font-size: 1.5rem;
}
.red {
  color: var(--light-red);
}
.blue {
  color: var(--light-blue);
  &.blinking {
    animation: blueblink 2s infinite;
  }
}
.light {
  color: var(--text2);
}

.script {
  letter-spacing: 0.8px;
  line-height: 1.4rem;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: 200;
}

.handwritten {
  font-family: 'Caveat', cursive;
  font-size: 1.8rem;
  letter-spacing: 0.1rem;

  &.big-text {
    font-size: 2.2rem;
  }
}

.underlined {
  text-underline-offset: 3.5px;
  text-decoration: dashed underline var(--border);;
}
a:not(.button-like) {
  text-decoration: dashed underline var(--border);
  text-underline-offset: 3.5px;
}

button, .button-like {
  border: 1px solid transparent;
  border-radius: 8px;

  &.bordered {
    border: 1px solid var(--border);

    &:hover, &:focus, &.active {
      border-color: var(--border2);
    }
  }
  &.borderless {
    border: none !important;
  }

  background-color: transparent;
  cursor: pointer;
  user-select: none;
  outline: none;
  transition: all 0.2s;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  
  > p.dynamic, > span.dynamic {
    transition: color 0.2s;
  }

  .tooltip {
    position: absolute;
    color: var(--text) !important;
    background-color: var(--back);
    padding: 3px 5px;
    border-radius: 3px;
    width: max-content;
    pointer-events: none;

    opacity: 0;
    z-index: -1;
    transform: translateY(30px);
    &.upward {
      transform: translateY(-30px);
    }
    &.leftward {
      transform: translateX(-50%);
    }
    &.rightward {
      transform: translateX(50%);
    }

    transition: opacity 0.2s, transform 0.2s;
  }

  &:hover, &:focus, &.active {
    border: 1px solid var(--border);
    color: var(--text3);
    box-shadow: none !important;

    .tooltip {
      opacity: 1;
      z-index: 2;
      transform: translateY(36px);

      &.upward {
        transform: translateY(-36px);
      }
      &.leftward {
        transform: translateX(calc(-50% - 20px));
      }
      &.rightward {
        transform: translateX(calc(50% + 20px));
      }
    }

    img.dynamic {
      opacity: 1 !important;
      filter: var(--blue-filter);
    
      &.red {
        // #943939
        filter: var(--red-filter);
      }
    }

    > p.dynamic, > span.dynamic {
      color: var(--blue);
      &.white { color: var(--text3); }
    }
  }
}

img, picture, video, canvas, svg {
  display: block;
  max-width: 100%;
  user-select: none;
}

img {
  &.inline {
    display: inline-block;
    height: 25px;
    width: 25px;
    position: relative;
    margin: 0 7px;
    top: 5px;
  }


  &.icon {
    // #555555
    filter: var(--grey-filter);

    height: 30px;
    width: 30px;

    &.small {
      height: 25px;
      width: 25px;
    }

    &.smaller {
      height: 20px;
      width: 20px;
    }

    &.dynamic {
      transition: filter 0.2s, opacity 0.2s;
    }
    
    &:not(.dynamic).blue {
      // #4860AC
      filter: var(--blue-filter);
    }
    &:not(.dynamic).red {
      // #943939
      filter: var(--red-filter);
    }

    &.white {
      filter: var(--white-filter);
    }
  }
}

.logo {
  font-family: 'Share Tech Mono', monospace;

  img {
    height: 30px;
    width: 30px;
    display: inline-block;
    position: relative;
    top: 5px;
  }
}

select {
  background-color: var(--back);
  padding: 5px;
  border-radius: 7px;
  font-size: 0.8rem;
}
input[type=range] {
  appearance: none;
  background-color: var(--border);
  cursor: pointer;
  height: 8px;
  border-radius: 4px;

  &::-webkit-slider-thumb {
    appearance: none;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: var(--border2);
    cursor: pointer;
    transition: background-color 0.2s;
  }
  &::-webkit-slider-thumb:hover {
    background-color: var(--text);
  }
}

input[type=text], textarea {
  background-color: transparent;
  padding: 5px;
  border-radius: 10px;
  border: 1px solid transparent;
  outline: none;
  transition: all 0.2s;

  &:hover, &:focus {
    border-color: var(--border2);
    color: var(--text3);
  }
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
::-webkit-scrollbar-track,
::-webkit-scrollbar-corner {
  background-color: transparent;
}
::-webkit-scrollbar-thumb {
  background-color: var(--border);
  border-radius: 10px;
}

@keyframes blueblink {
  0%, 100% {
    color: var(--text2);
  }
  50% {
    color: var(--light-blue);
  }
}
@keyframes redblink {
  0%, 100% {
    color: var(--text2);
  }
  50% {
    color: var(--light-red);
  }
}
@keyframes bluetextglow {
  0%, 100% {
    text-shadow: 0 0 6px var(--back);
  }
  50% {
    text-shadow: 0 0 6px var(--blue);
  }
}
@keyframes bluefilterglow {
  0%, 100% {
    filter: var(--grey-filter);
  }
  50% {
    filter: var(--blue-filter);
  }
}
@keyframes redfilterglow {
  0%, 100% {
    filter: var(--grey-filter);
  }
  50% {
    filter: var(--red-filter);
  }
}

.settings-group {
  display: flex;
  gap: 25px;
  flex-direction: column;
  width: 100%;
  padding: 15px;
  border-radius: 10px;
  background-color: var(--back2);

  .settings-label {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  select {
    padding: 5px 0;
    border: none;
    font-size: 0.9rem;
    cursor: pointer;
    transition: all 0.2s;
    text-align: center;
    background-color: var(--back2);;
    border: 1px solid var(--border);
    
    &:focus, &:hover {
      color: var(--text3);
      border-color: var(--border2);
    }
  }

  .radio-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    align-items: center;
    justify-content: center;
    min-width: calc(100% - 100px);
    
    margin: 0 auto;

    .label {
      flex: 1;
      white-space: nowrap;
    }
    button {
      color: var(--text2);
      &.active {
        background-color: var(--blue);
        color: var(--text3);

        > img {
          filter: var(--white-filter);
        }
      }
      &.disabled {
        color: var(--border2);
      }
    }
  }
  
  .flex-wrapper {
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    align-items: center;

    &.volume-wrapper > div {
      display: flex;
      gap: 15px;
      flex-direction: column;
      align-items: center;
      width: 100%;
      input {
        width: calc(100% - 50px);
      }
    }
  }
}
