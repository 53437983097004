#global-message {
  position: absolute;
  right: 35px;
  top: 58px;
  z-index: 4;
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: var(--red);
  opacity: 0.8;
  padding: 8px 15px 8px 10px;
  border-radius: 10px;

  transition: all 0.8s, background-color 0s;
  &.hidden {
    z-index: -1;
    opacity: 0;
    transform: translateX(30px);
    user-select: none;
  }
  img {
    filter: invert(93%) sepia(88%) saturate(4%) hue-rotate(259deg) brightness(104%) contrast(73%); /* --text */
  }
}
