#loading-screen-wrapper {
  z-index: 1000;
  background-color: var(--back);
  width: 100%;
  height: 100%;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;

  p {
    animation: bluetextglow 1.8s infinite;
    animation-delay: -0.7s;
  }
  
  p > img {
    animation: loadingblueglow 1.8s infinite;
    animation-delay: -0.7s;
    border-radius: 5px;
    display: inline-block;
    height: 30px;
    left: 10px;
    position: relative;
    top: 5px;
    width: 30px;
  }

  transition: opacity 0.4s;
  &.hidden {
    opacity: 0;
  }
}

#loading-screen {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 3rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;

  &:before, &:after {
    content: "";
    position: absolute;
    border-radius: 50%;
    animation: pulsOut 1.8s ease-in-out infinite;
    filter: drop-shadow(0 0 0.5rem var(--blue));
  }
  &:before {
    width: 100%;
    padding-bottom: 100%;
    box-shadow: inset 0 0 0 0.5rem #fff;
    animation-name: pulsIn;
  }
  &:after {
    width: calc(100% - 1rem);
    padding-bottom: calc(100% - 1rem);
    box-shadow: 0 0 0 0 #fff;
  }
}


@keyframes pulsIn {
  0% {
    box-shadow: inset 0 0 0 0.5rem var(--blue);
    opacity: 1;
  }
  50%, 100% {
    box-shadow: inset 0 0 0 0 var(--blue);
    opacity: 0;
  }
}

@keyframes pulsOut {
  0%, 50% {
    box-shadow: 0 0 0 0 var(--blue);
    opacity: 0;
  }
  100% {
    box-shadow: 0 0 0 0.5rem var(--blue);
    opacity: 1;
  }
}

@keyframes loadingblueglow {
  0%, 100% {
    box-shadow: 0 0 6px var(--back);
  }
  50% {
    box-shadow: 0 0 6px var(--blue);
  }
}
