#landing-page {
    $break-point: 1000px;
    $mobile-point: 511px;
    $cross-point: 768px;
    overflow: hidden;
    margin: 0 auto;
    padding-bottom: 200px;

    @media screen and (max-width: $mobile-point) {
       padding-bottom: 20px;
    }

    background-image: url("../../assets/arabesque.png");

    a.button-like {
        transition: color 0.2s, border-color 0.2s;
        padding: 10px 20px;
        border-radius: 20px;
        background-color: var(--back2);
        box-shadow: var(--strong-shadow);
        &:focus {
            color: var(--light-blue);
        }
    }

    .logo {
        margin: 0 5px;
        img {
            margin-left: 5px;
        }
    }

    #landing-header {
        position: fixed;
        z-index: 2;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 15px;
        width: calc(100% - 10px);
        margin: 0 auto;
        height: 60px;
        padding: 0 10px;
        border-radius: 0 0 20px 20px;
        transition: all 0.7s;

        a.button-like {
            transition: all 0.7s, color 0.2s;
        }
        &.hidden a.button-like {
            background-color: transparent;
            border-color: transparent;
            box-shadow: none;
        }
        
    }

    #intro {
        height: calc(100vh - 55px);
        width: 110%;
        max-width: 1300px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;

        #intro-text {
            flex: 0 0 460px;
            max-width: 460px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin: 0 0 0 100px;
            gap: 40px;

            h1 {
                font-size: 36px;
                text-align: center;
                line-height: 1.5;
            }
            .script {
                text-align: justify;
                font-size: 20px;
                line-height: 1.5;
            }
            a.button-like {
                border: 1px solid var(--border);
                color: var(--text2);
                padding: 15px 25px;
                border-radius: 30px;

                &:hover {
                    color: var(--text3);
                    border-color: var(--border2);
                }
            }
        }

        #intro-graphic {
            height: max(60vh, 450px);
            width: max(60vh, 450px);
            position: relative;

            border-radius: 50%;
            background-image: url("./assets/morris.svg");
            background-size: contain;

            #circle {
                height: 100%;
                width: 100%;
                border-radius: 50%;
                opacity: 0.5;
                background: linear-gradient(110deg, var(--light-red), var(--light-blue));
                background-size: 200% 200%;
                transform: rotate(0deg);
                animation: gradrotate 60s infinite;
            }

            @keyframes gradrotate {
              0%, 100% {
                  background-position: 0% 50%;
                  transform: rotate(0deg);
              }
              25% {
                  background-position: 100% 25%;
              }
              50% {
                  background-position: 0% 100%;
              }
              75% {
                  background-position: 100% 75%;
                  transform: rotate(180deg);
              }
            }

            .intro-module {
                position: absolute;       
                background-color: var(--back);
                padding: 15px 20px;
                border-radius: 30px;
                box-shadow: var(--strong-shadow);
                display: flex;
                align-items: center;
                gap: 15px;

                color: var(--text2);
                border: 1px solid var(--border);  
                cursor: pointer;
                &:hover {
                    border-color: var(--blue);
                    color: var(--light-blue);
                }

                @at-root #intro-graphic.hidden .intro-module {
                    opacity: 0;
                    transform: translate(-20px, -50%) !important;
                }
                transform: translate(-40px, -50%);
                transition: all 0.2s, opacity 1.5s, transform 2s;

                animation: float 6s ease-in-out infinite;

                &:nth-child(odd) {
                    animation: float2 5s ease-in-out infinite;
                }
                &:nth-child(1), &:nth-child(4) {
                    animation-delay: 2s;
                }
                &:nth-child(3), &:nth-child(6) {
                    animation-delay: 3s;
                }
                &:nth-child(5), &:nth-child(2) {
                    animation-delay: 4s;
                }

                @keyframes float {
                    0%, 100% {
                        transform: translate(-40px, -50%);
                    }
                    25% {
                        transform: translate(-42px, -48%);
                    }
                    50% {
                        transform: translate(-38px, -52%);
                    }
                    75% {
                        transform: translate(-41px, -48%);
                    }
                }

                @keyframes float2 {
                    0%, 100% {
                        transform: translate(-40px, -50%);
                    }
                    25% {
                        transform: translate(-38px, -52%);
                    }
                    50% {
                        transform: translate(-41px, -49%);
                    }
                    75% {
                        transform: translate(-42px, -48%);
                    }
                }

                img {
                    height: 30px;
                    width: 30px;
                }

            }
        }

        @media screen and (max-width: $break-point) {
            justify-content: center;
            width: 100%;

            #intro-text {
                margin: 0 10px;
                flex: 1;
            }
            #intro-graphic {
                display: none;
            }
        }
    }

    .for-tutors-students {
        display: flex;
        justify-content: space-around;
        margin: 0 0 5px 0;
    }

    #demo-wrapper {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 20px;
        margin: 0 auto 250px auto;
        width: calc(100% - 20px);
        max-width: 1600px;
        position: relative;

        #student-view, #tutor-view {
            flex: 1;
            min-width: 480px;
            margin: 40px auto 0 auto;
            border: 2px solid;
            border-radius: 20px;

            position: relative;
            span {
                position: absolute;
                left: 50%;
                top: -45px;
                transform: translateX(-50%);
                white-space: nowrap;
            }
            
            &#tutor-view {
                border-color: var(--red);
                background-color: #150C16;

                .tutorVid {
                    width: 24.8%;
                    left: 1.82%;
                    bottom: 4%;
                }
                .studentVid {
                    width: 53.9%;
                    height: 56.9%;
                    border-radius: 2%;
                    left: 41.8%;
                    top: 12%;
                }
                .peerPianoVid {
                    width: 64.8%;
                    left: 28.5%;
                    bottom: 13.5%;
                }
            }
            &#student-view {
                border-color: var(--blue);
                background-color: #0F0C1A;

                .tutorVid { 
                    width: 34.9%;
                    height: 27.5%;
                    left: 2.2%;
                    bottom: 41.4%;
                }
                .studentVid {
                    width: 18.9%;
                    height: 19.6%;
                    left: 4.9%;
                    bottom: 4.15%;
                }
                .selfPianoVid {
                    width: 64.8%;
                    left: 27.5%;
                    bottom: 2.2%;
                }
            }

            svg, img {
                // width: 100%;
                transition: opacity 0.7s;
            }

            @media screen and (max-width: $mobile-point) {
                min-width: 100%;
            }
        }

        
        &.hidden {
            #student-view svg, #tutor-view svg, .studentVid, .tutorVid, .peerPianoVid, .selfPianoVid {
                opacity: 0;
            }
        }
        .tutorVid, .studentVid, .peerPianoVid, .selfPianoVid {
            position: absolute;
            z-index: 1;
            border-radius: 4px;
        }

        #encrypted-wrapper {
            position: absolute;
            z-index: -1;
            left: 50%;
            bottom: 0;
            transform: translate(-50%, 50%);
            width: 220px;
            height: 220px;
            border-radius: 50%;
            background: linear-gradient(135deg, rgba(170,66,66,0.2) 0%, rgba(75,104,198,0.2) 100%);
            border: 2px dashed var(--blue);

            >div {
                position: absolute;
                left: 50%;
                top: 60%;
                transform: translateX(-50%);
                width: 152px;
                display: flex;
                gap: 10px;
            }
        }

        @media screen and (max-width: $break-point) {
            #encrypted-wrapper {
                display: none;
            }

            margin-bottom: 100px;
        }
    }

    .for-tutors, .for-students {
        position: absolute;
        width: 380px;
        height: 95px;
        display: flex;
        align-items: center;
        border-radius: 25px;
        gap: 20px;
        padding: 0 10px 0 25px;
        background-color: var(--back);
        box-shadow: var(--strong-shadow);

        img {
            height: 45px;
            width: 45px;
        }
        span {
            position: absolute;
            left: 50%;
            top: -45px;
            width: max-content;
            transform: translateX(-50%);
        }
        p {
            line-height: 33px;
        }

        &.for-tutors {
            left: 25%;
            border: 2px solid var(--dark-red);
        }
        &.for-students {
            left: 75%;
            border: 2px solid var(--dark-blue);
        }
        transform: translateX(-50%);
        top: -50px;
        
        @media screen and (max-width: $cross-point) {
            display: none;
        }
    }

    .pane {
        position: relative;
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 20px;
        padding: 110px 10px 50px 10px;
        position: relative;
        background-repeat: repeat;
        background-color: var(--back2);
        width: calc(100% - 20px);
        max-width: 1300px;
        margin: 0 auto;
        border-radius: 20px;
        animation: scrollBackground 120s infinite linear;

        transition: opacity 0.7s, transform 0.7s;
        &.hidden {
            opacity: 0;
            transform: translateY(50px);
        }

        .question {
            margin-bottom: 25px;
            text-shadow: var(--text-shadow);
            text-align: center;
            > span {
                display: inline-block; // To rotate independently
                font-family: 'Caveat', cursive;
                margin: 0px 5px;
            }
        }

        @media screen and (max-width: $mobile-point) {
            display: none;
        }
    }

    @keyframes scrollBackground {
        from {
            background-position: 0 0px;
        }
        to {
            background-position: -1300px -1711px;
        }
    }

    #features-wrapper {
        background-image: url("./assets/jac.svg");
        // background-size: cover;
        background-size: 90%;

        .for-tutors {
            padding-left: 20px;
        }
        .question {
            span:nth-child(1){
                transform: rotate(1deg);
            }
            span:nth-child(2) {
                transform: rotate(-0.5deg);
            }
        }

        .expandable-module {
            background-color: var(--back);
            padding: 15px 0;
            border: 1px solid var(--border2);
            border-radius: 41px;
            width: 100%;
            max-width: 578px;
            height: 70px;
            display: flex;
            justify-content: center;
            align-items: center;
            box-shadow: var(--strong-shadow);
            gap: 20px;
            position: relative;
            cursor: pointer;

            .coming-soon {
                width: min-content;
                position: absolute;
                left: 10px;
                font-size: 1.3rem;
                text-align: center;
                transform: rotate(-15deg);
            }

            .keycon, .trad-calling {
                font-size: 2.5rem;
                width: 20px;
                text-align: center;
                flex: 1;
                height: 0px;
                position: absolute;
                top: 3px;
            }
            .keycon {
                left: 80px;
                top: 7px;
            }
            .trad-calling {
                right: 80px;
            }

            transition: border-color 0.2s, height 0.3s;
            &:hover, &:focus {
                border-color: var(--blue);
                outline: none;
            }
        }
    }


    .setup-option {
        height: min-content;
        display: flex;
        flex-direction: column;
        gap: 10px;
        background-color: #0f0c16bb;
        padding: 20px 15px 20px 20px;
        border-radius: 20px;
        border: 1px solid var(--dark-red);

        p {
            font-weight: 400;
        }
        .handwritten {
            text-align: center;
        }

        &.blue {
            border: 2px solid var(--blue);
            box-shadow: var(--light-shadow);
            padding: 25px 0 0 0;
            width: 180px;
            height: 120px;
            align-items: center;

            >p:first-of-type {
                flex: 1;
            }
            >p.handwritten {
                background-color: var(--blue);
                color:var(--text3);
                width: 100%;
                border-radius: 0 0 15px 15px;
                text-align: center;
                padding: 5px 0;
            }
        }
    }

    #setup-wrapper {
        margin-top: 150px;
        background: linear-gradient(135deg, #251927 0%, #1F1C30 100%);
        animation: none;

        .for-tutors {
            gap: 25px;
            padding-left: 28px;
        }
        .for-students {
            width: 340px;
            gap: 30px;
        }
        .question:first-of-type {
            transform: rotate(0.2deg);
            
            span:nth-of-type(1) {
                transform: rotate(-2deg);
                img {
                    transform: rotate(-3deg);
                }
            }
            span:nth-of-type(2) {
                transform: rotate(2.5deg);
                img {
                    transform: rotate(-3.5deg);
                }
            }
        }
        .question:nth-of-type(2) {
            margin-top: 60px;
            .logo img {
                margin-right: 10px;
            }
        }
        

        #old-setup-wrapper {
            > p:first-of-type {
                font-weight: 500;
                text-shadow: var(--text-shadow);
                text-align: center;
            }

            > div {
                display: flex;
                justify-content: center;
                gap: 20px;
                margin: 20px 0 0 0;
            }

            > div:nth-of-type(2) {
                gap: 100px;
                margin-top: 40px;
            }

            #drawn-diagram {
                @media screen and (max-width: $cross-point) {
                    display: none;
                }
            }
        }

    }

    #camera-setup-wrapper {
        margin: 20px auto;
        background: linear-gradient(45deg, #251927 0%, #1F1C30 100%);
        animation: none;

        .question {
            img {
                height: 60px;
                width: 60px;
                background-color: #00000099;
                opacity: 0.7;
                padding: 7px;
                border-radius: 5px;
                position: absolute;
                left: calc(50% - 30px);
                top: -70px;

                @media screen and (max-width: 633px) {
                    display: none;
                }
            }
            span:nth-of-type(1) {
                transform: rotate(-1deg);
                img {
                    transform: rotate(1.5deg);
                }
            }
            span:nth-of-type(2) {
                transform: rotate(1.5deg);
                img {
                    transform: rotate(1.5deg);
                }
            }
        }

        > div {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 25px;
        }
        
        .setup-option:not(.blue) {
            margin: 0 35px 0 0;
        }

        #camera-keycon {
            display: flex;
            flex-direction: column;
            gap: 10px;
            align-items: center;
            img {
                display: inline-block;
                height: 30px;
                width: 30px;
                position: relative;
                left: 10px;
                top: 5px;
            }

            >p {
                font-size: 1.5rem;
            }


        }
    }

    #cta-wrapper {
        display: flex;
        justify-content: space-evenly;
        margin: 120px auto 0 auto;
        max-width: 1200px;

        transition: opacity 0.7s, transform 0.7s;
        &.hidden {
            opacity: 0;
            transform: translateY(50px);
        }
        
        .cta {
            border-radius: 20px;
            padding: 40px;
            gap: 30px;
            display: flex;
            flex-direction: column;
            align-items: center;
            background-color: #00000044;
            
            .handwritten {
                font-size: 1.5rem;
                > span {
                    font-size: 1.4rem;
                    margin: 0 5px;
                }
            }

            .button-like {
                width: 200px;
                background-color: var(--blue);
            }

            border: 1px solid var(--dark-blue);
            &:nth-child(2) {
                border-color: var(--dark-red);
                > div {
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    .button-like {
                        width: max-content;
                        background-color: var(--red);
                    }
                }
            }
        }

        @media screen and (max-width: $mobile-point) {
            .cta:first-child {
                display: none;
            }
        }
    }
}

